import { PatientProcedureVO, SortOrder } from "@libs/api/generated-api";
import {
  BoolVal,
  StrVal,
  routesConfigHelper,
  parseEnum,
  withDefault,
  ParsedParams,
  StrCSV,
  Enum,
} from "@libs/router/url";
import { split } from "@libs/utils/split";
import { PatientPathParams } from "utils/routing/patient";

const PerioChartQueryParams = { uuid: StrVal };
const PerioChartCompareQueryParams = {
  uuids: StrCSV,
  tab: withDefault(Enum<"GINGMARGIN" | "PROBING">().Val(["GINGMARGIN", "PROBING"]), "PROBING"),
};

export type PatientProcedureSortColumns = "date" | "procedureArea" | "priority" | "createdAt";

const patientProcedureSortColumns = new Set<PatientProcedureSortColumns>([
  "date",
  "procedureArea",
  "priority",
  "createdAt",
]);
const patientProcedureSortDirections = new Set<SortOrder["direction"]>(["ASCENDING", "DESCENDING"]);

const PatientProceduresSort = <SC extends string>(tableSortColumns: Set<SC>) => ({
  get: (val: string) => {
    const sort = split(val, ",").map((fields) => split(fields, "-"));

    for (const [column, direction] of sort) {
      parseEnum(tableSortColumns, column);
      parseEnum(patientProcedureSortDirections, direction || "");
    }

    return sort as [SC, SortOrder["direction"]][];
  },

  set: (val: [SC, SortOrder["direction"]][]) =>
    val.map(([column, direction]) => `${column}-${direction}`).join(","),
});

export type DefaultSortMap<S extends PatientProcedureSortColumns> = Record<
  S,
  [PatientProcedureSortColumns, SortOrder["direction"]][]
>;

export const masterTpSortingPerPrimaryKey: DefaultSortMap<PatientProcedureSortColumns> = {
  priority: [
    ["priority", "ASCENDING"],
    ["date", "ASCENDING"],
    ["procedureArea", "ASCENDING"],
  ],
  date: [
    ["date", "ASCENDING"],
    ["priority", "ASCENDING"],
    ["procedureArea", "ASCENDING"],
  ],
  procedureArea: [
    ["procedureArea", "ASCENDING"],
    ["priority", "ASCENDING"],
    ["date", "ASCENDING"],
  ],
  createdAt: [
    ["createdAt", "ASCENDING"],
    ["priority", "ASCENDING"],
    ["procedureArea", "ASCENDING"],
  ],
};

export const ChartingRoutesConfig = routesConfigHelper({
  charting: {
    path: "/patients/:patientId/charting",
    query: {
      patientProcedureStatuses: withDefault(
        Enum<PatientProcedureVO["status"]>().CSV(
          ["EXISTING_CURRENT", "EXISTING_OTHER", "REFERRED", "REJECTED", "DONE", "PLANNED", "SCHEDULED"],
          "[]"
        ),
        ["REFERRED", "SCHEDULED", "PLANNED"]
      ),
      treatmentPlanUuid: StrVal,
      tableFullScreen: BoolVal,
      sort: withDefault(
        PatientProceduresSort(patientProcedureSortColumns),
        masterTpSortingPerPrimaryKey.priority
      ),
    },
    params: PatientPathParams,
  },
  perio: {
    path: "/patients/:patientId/charting/perio",
    params: PatientPathParams,
    query: PerioChartQueryParams,
  },
  perioCompare: {
    path: "/patients/:patientId/charting/perio/compare",
    params: PatientPathParams,
    query: PerioChartCompareQueryParams,
  },
});

export type ChartingQuery = ParsedParams<(typeof ChartingRoutesConfig.charting)["query"]>;
export type ChartingQueryUpdates = Partial<ChartingQuery>;
