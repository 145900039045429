import {
  EmployeeRolesVO,
  PermissionActionVO,
  PermissionConditionalContent,
  RolePermissionActionsRequest,
  RoleRequest,
  RoleVO,
} from "@libs/api/generated-api";
import { filterMap, hasItems, Some } from "@libs/utils/array";
import {
  DOMAINS_LOCKED,
  SUB_PERMISSION_EFFECT_IF_MISSING,
  SORTED_ACTIONS_BY_DOMAIN,
  SORTED_DOMAINS,
  DOMAIN_EFFECT_IF_MISSING,
  PermissionAction,
} from "components/Roles/constants";

/**
 * Groups the permissions based on their domains and actions.
 *
 * @param permissions An array of PermissionActionVO objects representing the permissions.
 * @returns An array of arrays, where each inner array contains the permissions grouped by domain.
 */
export const groupPermissions = (permissions: PermissionActionVO[]): Some<PermissionActionVO>[] => {
  const grouped = SORTED_DOMAINS.reduce<PermissionActionVO[][]>((sortedDomainPermissions, domain) => {
    const sortedActions = SORTED_ACTIONS_BY_DOMAIN[domain];
    const domainPermissions = sortedActions.reduce<PermissionActionVO[]>((sortedPermissions, action) => {
      // Find the permission that matches the current domain and action
      const permission = permissions.find((p) => p.domain === domain && p.action === action);

      if (permission != null) {
        // Add the permission to the domainPermissions array if found
        return [...sortedPermissions, permission];
      }

      // Otherwise, return the current result array.
      return sortedPermissions;
    }, []);

    // Add the domainPermissions array to the result array
    return [...sortedDomainPermissions, domainPermissions];
  }, []);

  return filterMap(grouped, (group) => (hasItems(group) ? group : null));
};
/**
 * Converts an array of permissions to a RoleRequest object representing a role draft.
 *
 * @param permissions An array of PermissionActionVO objects representing the permissions.
 * @returns A RoleRequest object representing the role draft.
 */
export const permissionsToRoleDraft = (permissions: PermissionActionVO[]): RoleRequest => ({
  name: "",
  // By default, all domain permissions are denied, unless they are in the
  // `PERMISSIONS_ON_BY_DEFAULT` set. Sub-permissions are allowed by default
  // such that when a domain permission is turned on, all sub-permissions are
  // also turned on.
  permissionActions: permissions.map((permission) => ({
    permissionActionUuid: permission.uuid,
    effect: DOMAINS_LOCKED.has(permission.domain) || permission.action !== "ACCESS_ALL" ? "ALLOW" : "DENY",
  })),
});

/**
 * Converts an existing role to a RoleRequest object representing a role draft.
 *
 * @param permissions An array of PermissionActionVO objects representing the permissions.
 * @param role The existing RoleVO object to be converted.
 * @returns A RoleRequest object representing the role draft.
 */
export const roleToRoleDraft = (permissions: PermissionActionVO[], role: RoleVO): RoleRequest => ({
  name: role.name,
  // Iterate over all permissions. If the permission is in the `PERMISSIONS_ON_BY_DEFAULT` set, then
  // it is allowed by default so set "ALLOW". If the permission exists in the role, then use what
  // the permission is set to. Otherwise, set "DENY".
  permissionActions: permissions.map((permission) => {
    const matchingRolePermission = role.rolePermissionActions.find(
      (rolePermission) => rolePermission.permissionAction.uuid === permission.uuid
    );

    return {
      permissionActionUuid: permission.uuid,
      effect: matchingRolePermission
        ? matchingRolePermission.effect
        : isDomainPermission(permission)
          ? DOMAIN_EFFECT_IF_MISSING
          : SUB_PERMISSION_EFFECT_IF_MISSING,
      ...(matchingRolePermission?.conditions && { conditions: matchingRolePermission.conditions }),
    };
  }),
});

const isDomainPermission = (permission: PermissionActionVO) => permission.action === "ACCESS_ALL";
const isSubPermission = (permission: PermissionActionVO) => !isDomainPermission(permission);
const isSameDomain = (permission1: PermissionActionVO, permission2: PermissionActionVO) =>
  permission1.domain === permission2.domain;

/**
 * Prepares a role draft for submission by filtering out unnecessary permissions.
 *
 * @param permissions An array of PermissionActionVO objects representing the available permissions.
 * @param roleDraft A RoleRequest object representing the role draft to be prepared for submission.
 * @returns A modified RoleRequest object with unnecessary permissions filtered out.
 */
export const prepareRoleDraftForSubmission = (permissions: PermissionActionVO[], roleDraft: RoleRequest) => {
  const deniedDomainPermissions = permissions.filter(
    (p) =>
      isDomainPermission(p) &&
      roleDraft.permissionActions.some((rp) => rp.permissionActionUuid === p.uuid && rp.effect === "DENY")
  );

  const newPermissions = roleDraft.permissionActions.reduce<RolePermissionActionsRequest[]>(
    (reduced, rolePermission) => {
      const matchingPermission = permissions.find((p) => p.uuid === rolePermission.permissionActionUuid);

      // If matchingPermission is a domain permission, then retain it as-is.
      if (matchingPermission && isDomainPermission(matchingPermission)) {
        return [...reduced, rolePermission];
      }

      // If matchingPermission is a sub-permission, check if its domain permission is turned off and
      // drop the sub-permission. Otherwise, retain the sub-permission as-is.
      if (matchingPermission && isSubPermission(matchingPermission)) {
        const deniedDomain = deniedDomainPermissions.find((denied) =>
          isSameDomain(denied, matchingPermission)
        );

        if (deniedDomain) {
          return reduced;
        }

        return [...reduced, rolePermission];
      }

      // Otherwise, return the current newPermissions array
      return reduced;
    },
    []
  );

  return {
    ...roleDraft,
    permissionActions: newPermissions,
  };
};

export type RoleConditionHandler = (conditions: PermissionConditionalContent) => boolean;

type RoleFailureReason = "UNMET_CONDITIONS" | "INSUFFICIENT_PERMISSIONS";
export type PermissionResults = { isPermitted: boolean; failureReason?: RoleFailureReason };

const buildPermissionMap = (role: RoleVO) => {
  return new Map(
    role.rolePermissionActions.map((permission) => [
      permissionKey(permission.permissionAction.domain, permission.permissionAction.action),
      permission,
    ])
  );
};

const permissionKey = (domain: PermissionActionVO["domain"], action: string) => `${domain}-${action}`;

/**
 * Checks if a role has permission to perform a specific action on a domain.
 * @param role - The role to check permissions for.
 * @param domain - The domain on which the action is performed.
 * @param action - The action to be performed on the domain.
 * @param onRoleCondition - Optional handler function to check additional role
 * conditions. If the role has a condition but no condition handler is provided,
 * the condition is assumed to be met.
 * @returns An object containing the permission results.
 */
export const checkPermission = (
  role: RoleVO,
  domain: PermissionActionVO["domain"],
  action: PermissionAction,
  onRoleCondition?: RoleConditionHandler
): PermissionResults => {
  const permissionsMap = buildPermissionMap(role);

  const matchingPermission = permissionsMap.get(permissionKey(domain, action));
  const domainPermission = permissionsMap.get(permissionKey(domain, "ACCESS_ALL"));

  // If a new permission is introduced but isn't on the role yet, then the domain permission
  // dictates the behavior.
  if (!matchingPermission) {
    const domainIsPermitted = action !== "ACCESS_ALL" && domainPermission?.effect === "ALLOW";

    return {
      isPermitted: domainIsPermitted,
      ...(domainIsPermitted ? undefined : { failureReason: "INSUFFICIENT_PERMISSIONS" }),
    };
  }

  // If the permission is found, then check if the domain permission is set to ALLOW and the
  // permission is set to ALLOW.
  const isPermitted = domainPermission?.effect === "ALLOW" && matchingPermission.effect === "ALLOW";

  // If we're not permitted, then just return the results.
  if (!isPermitted) {
    return { isPermitted, failureReason: "INSUFFICIENT_PERMISSIONS" };
  }

  // We are permitted, but check if any conditions can be met. If we don't have a condition checker,
  // assume the conditions are met and that the caller didn't want to check the condition and only
  // the domain/action.
  if (!onRoleCondition || !matchingPermission.conditions) {
    return { isPermitted };
  }

  const isConditionMet = onRoleCondition(matchingPermission.conditions);

  return { isPermitted: isConditionMet, failureReason: isConditionMet ? undefined : "UNMET_CONDITIONS" };
};

const MIGRATION_ROLE = "Limited";

/**
 * Checks if a role is a migration role.
 * @param role - The role to check.
 * @returns True if the role is a migration role, false otherwise.
 */
export const isMigrationRole = (role: RoleVO) => {
  return role.name === MIGRATION_ROLE;
};

type PermissionObject = {
  domain: PermissionActionVO["domain"];
  action: PermissionAction;
};

export const findFirstMatchingRole = <T extends PermissionObject>(roleV2: RoleVO, permissions: T[]) => {
  return permissions.find(
    (permission) => checkPermission(roleV2, permission.domain, permission.action).isPermitted
  );
};

export const findRoleById = (roleId: number, roles: EmployeeRolesVO[]) => {
  return roles.find((role) => role.role.roleId === roleId);
};
