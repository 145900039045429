import type { EnvTypeConfig } from "envTypeConfig";

type OptionalVars = EnvTypeConfig["optional"];
type RequiredVars = EnvTypeConfig["required"];

// Get an env object type that filters out env variables that are not prefixed
// with `REACT_APP_`. Values under the optional section are typed as optional.
// Values under the required section are typed as required.
type ReactAppKeys<T> = { [P in keyof T]: P extends `REACT_APP_${string}` ? P : never }[keyof T];
type DerivedEnvValues = {
  [K in ReactAppKeys<RequiredVars>]: RequiredVars[K] extends string
    ? string
    : RequiredVars[K] extends Readonly<Array<infer D>>
      ? D
      : never;
} & {
  [K in ReactAppKeys<OptionalVars>]?: OptionalVars[K] extends string
    ? string
    : OptionalVars[K] extends Readonly<Array<infer D>>
      ? D
      : never;
};

const importEnv = import.meta.env as unknown as DerivedEnvValues;

export const env = {
  REACT_APP_API_HOST: importEnv.REACT_APP_API_HOST,
  REACT_APP_AUTH_DOMAIN: importEnv.REACT_APP_AUTH_DOMAIN,
  REACT_APP_COGNITO_ENDPOINT: importEnv.REACT_APP_COGNITO_ENDPOINT,
  REACT_APP_COGNITO_USER_POOL_ID: importEnv.REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID: importEnv.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_DWT_HANDSHAKE_CODE: importEnv.REACT_APP_DWT_HANDSHAKE_CODE,
  REACT_APP_ENVIRONMENT: importEnv.REACT_APP_ENVIRONMENT,
  REACT_APP_FINIX_APPLICATION_ID: importEnv.REACT_APP_FINIX_APPLICATION_ID,
  REACT_APP_FINIX_ENVIRONMENT: importEnv.REACT_APP_FINIX_ENVIRONMENT,
  REACT_APP_GOOGLE_API_KEY: importEnv.REACT_APP_GOOGLE_API_KEY,
  REACT_APP_PATIENT_PORTAL_HOST: importEnv.REACT_APP_PATIENT_PORTAL_HOST,
  REACT_APP_PUBLIC_ASSETS_HOST: importEnv.REACT_APP_PUBLIC_ASSETS_HOST,
  REACT_APP_RELEASE_VERSION: importEnv.REACT_APP_RELEASE_VERSION,
  REACT_APP_UNLAYER_PROJECT_ID: importEnv.REACT_APP_UNLAYER_PROJECT_ID,
  REACT_APP_WEBSOCKET_HOST: importEnv.REACT_APP_WEBSOCKET_HOST,
  REACT_APP_ZENDESK_SSO_URL: importEnv.REACT_APP_ZENDESK_SSO_URL,
};

export type EnvValues = typeof env;
