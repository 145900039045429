import { FC, ReactNode, useId } from "react";
import { Icon } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";

interface Props {
  title: string;
  description: ReactNode;
  SvgIcon: IconComponent;
  padding?: "sm" | "md";
  className?: string;
  layout?: "stack" | "row";
}

const cxPadding = {
  sm: "px-3 pt-3 pb-5",
  md: "p-6",
};

export const IconCard: FC<Props> = ({
  title,
  description,
  SvgIcon,
  padding = "md",
  layout = "row",
  className,
}) => {
  const id = useId();

  return (
    <div
      className={cx(
        "rounded border border-slate-200 flex",
        cxPadding[padding],
        layout === "row" ? "items-center gap-x-6" : " flex-col items-start gap-y-1",
        className
      )}
    >
      <Icon SvgIcon={SvgIcon} />
      <div className="text-xs">
        <p id={id} className="font-sansSemiBold mb-1">
          {title}
        </p>
        <p aria-labelledby={id}>{description}</p>
      </div>
    </div>
  );
};
