import { Enum, routesConfigHelper } from "@libs/router/url";

// All info is the old experience, and will be removed after testing on prod is complete
export type OnboardStep =
  | "personal-info"
  | "licensing-info"
  | "practice-owner"
  | "practice-info"
  | "business-info";

export const OnboardingRoutesConfig = routesConfigHelper({
  onboarding: {
    path: "/onboarding/:step",
    params: {
      step: Enum<OnboardStep>().Val([
        "personal-info",
        "licensing-info",
        "practice-owner",
        "practice-info",
        "business-info",
      ]),
    },
  },
});
