import { PracticeInfoVO } from "@libs/api/generated-api";
import { getLocalDate, nowInTimezone } from "@libs/utils/date";
import { CurrentUser } from "contexts/CurrentUserContext";

export const hasAccess = (accessDate: string, timezoneId: string) => {
  return Boolean(getLocalDate(accessDate) <= nowInTimezone(timezoneId));
};

export const hasCompletedOnboardingForms = (
  user: CurrentUser,
  onboardingStatus?: PracticeInfoVO["onboardingStatus"]
) => {
  if (user.type !== "EMPLOYEE") {
    return true;
  }

  return user.employmentDetails.status !== "PENDING" && onboardingStatus !== "PROVISIONING";
};

export const isUserOnboarding = (
  user: CurrentUser,
  timezoneId: string,
  onboardingStatus?: PracticeInfoVO["onboardingStatus"]
) => {
  if (user.type !== "EMPLOYEE") {
    return false;
  }

  return (
    !timezoneId ||
    !hasAccess(user.employmentDetails.accessDate, timezoneId) ||
    !hasCompletedOnboardingForms(user, onboardingStatus)
  );
};
