import { FC, PropsWithChildren } from "react";

export const NotesEmptyContent: FC<PropsWithChildren<{ title: string }>> = ({ title, children }) => {
  return (
    <div className="bg-slate-50 p-6 rounded">
      <div className="text-sm font-sansSemiBold mb-3">{title}</div>
      <div className="text-xs">{children}</div>
    </div>
  );
};
